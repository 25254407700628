import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/700.css';
import './src/styles/base.css';
import './src/styles/common.css';
import './src/styles/header-footer-custom.less';
import './src/styles/oep-banner.less';
import * as React from 'react';
import NotSupported from './src/components/core/layout/NotSupported';
import { initializeAllianceId } from './src/alliance';
import { createPageTracking } from './src/tracking';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

initializeAllianceId();

createPageTracking();

export const wrapPageElement = isIE ? () => <NotSupported /> : () => {};

const addScript = () => {
  const script = document.createElement('script');
  script.innerHTML = `
    var __dcid = __dcid || [];
    __dcid.push({"cid":"DigiCertClickID_onwANETs","tag":"onwANETs"});
    setTimeout(function() {
      (function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
    }, 600);
  `;
  document.head.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript();
  };
};

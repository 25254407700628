const constants = Object.freeze({
  DESKTOP_BREAKPOINT: 992,
  TABLET_BREAKPOINT: 768,
  /* discuss about the default phone/allid values */
  DEFAULT_PHONE: '1-844-229-4337',
  DEFAULT_ALLIANCE_ID: 'ehe12862000',
  DEFAULT_SMB_PHONE: '1-800-555-5555',
  DEFAULT_SMB_HOURS: 'Mon-Fri 9AM-7PM ET',
  DEFAULT_SEO_ALLIANCE_ID: 'seo11276000',
  ALLIANCE_ID_COOKIE_KEY: 'ehi.alliance_id',
  ALLIANCE_PID_COOKIE_KEY: 'ehi.alliance_pid',
  ALLIANCE_SID_COOKIE_KEY: 'ehi.alliance_sid',
  ALLIANCE_CAMPAIGN_ID_COOKIE_KEY: 'ehi.alliance_campaign',
  QASCRIPT_COOKIE_KEY: 'qascript',
});

export default constants;

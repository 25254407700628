import constants from './globals/constants/application-constants';
import { getUrlParam, setBrowserStorage, setCookieStorage, getCookieStorage } from './common';

export function isSEO(): boolean {
  let { referrer } = document;

  if (referrer && referrer.match(/:\/\/(.[^/]+)/)[1] === location.hostname) {
    return false;
  }

  referrer = getUrlParam('adobe_mc_ref') || document.referrer || 'direct';
  const seoDomains = [
    'google',
    'ask.com',
    'bing.com',
    'yahoo',
    'aol',
    'yandex.com',
    'yam.com',
    'wow.com',
    'webcrawler.com',
    'voila.fr',
    'virgilio.it',
    'vinden.nl',
    'twcc.com',
    'thenexttask.com',
    'terra.com',
    'teoma.com',
    'startpagina.nl',
    'smarter.com',
    'seznam.cz',
    'verizon',
    'xfinity',
    'whiteskyservices',
    'pch',
    'mediacomcable',
    'search',
    'reference.com',
    'rambler.ru',
    'netscape.com',
    'naver.com',
    'msn.com',
    'lycos.com',
    'kvasir.no',
    'ixquick.com',
    'greenwichmeantime.com',
    'airtel',
    'frontier.com',
    'eniro.se',
    'ekolay.net',
    'earthlink.net',
    'duckduckgo.com',
    'dogpile.com',
    'att',
    'daum.net',
    'contenko.com',
    'baidu.com',
    'altavista.com',
    'alot.com',
    'alltheweb.com',
    'alice.com',
    'about.com',
    'wp.pl',
  ];

  return seoDomains.some((domain) => referrer.includes(domain));
}

function saveAttributions(params: Record<string, string>) {
  const attributions = ['sid', 'pid', 'gclid', 'campaign'];

  attributions.forEach((attr) => {
    if (
      Object.prototype.hasOwnProperty.call(params, attr) ||
      Object.prototype.hasOwnProperty.call(params, attr.toUpperCase())
    ) {
      const attrValue = params[attr] || params[attr.toUpperCase()];
      setBrowserStorage(attr, attrValue);
      setCookieStorage(`ehi.alliance_${attr}`, attrValue);
    }
  });
}

export function initializeAllianceId(): void {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const existingCookie = getCookieStorage(constants.ALLIANCE_ID_COOKIE_KEY);
  const cameFromSEO = isSEO();
  const urlParamsAllid = params.allid;
  const urlParamsQascript = params.qascript;
  const defaultAllidSEO = constants.DEFAULT_SEO_ALLIANCE_ID;
  const defaultAllid = constants.DEFAULT_ALLIANCE_ID;

  let selectedAllid = defaultAllid;
  if (urlParamsAllid) {
    selectedAllid = urlParamsAllid;
  } else if (cameFromSEO) {
    selectedAllid = defaultAllidSEO;
  } else if (existingCookie) {
    selectedAllid = existingCookie;
  }
  if (urlParamsQascript) {
    setCookieStorage(constants.QASCRIPT_COOKIE_KEY, urlParamsQascript);
  } 
  setBrowserStorage('allid', selectedAllid);
  setCookieStorage(constants.ALLIANCE_ID_COOKIE_KEY, selectedAllid);
  saveAttributions(params);
}
